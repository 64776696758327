import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import TunisiaJobs from "../assets/img/tunisia-jobs-5.jpg";
import TunisiaJobs51 from "../assets/img/tunisia-jobs-5-1.png";
import TunisiaJobs52 from "../assets/img/tunisia-jobs-5-2.png";
import TunisiaJobs53 from "../assets/img/tunisia-jobs-5-3.png";
import TunisiaJobs54 from "../assets/img/tunisia-jobs-5-4.png";
import SPI from "../assets/img/SPI.jpg";
import IBS from "../assets/img/IBS.jpg";
import grants from "../assets/img/grants-1.png";
import grants3 from "../assets/img/grants-3.png";
import CDC from "../assets/img/CDC-min.jpg";

const Covid19Response = () => (
  <Layout>
    <SEO title="Covid-19 Response | Visit Tunisia Project" />
    <main role="main">
      <div className="container-fluid">
        <div className="region region-banner">
          <div
            id="block-covid19response"
            className="block block-block-content block-block-content0a6e7bb6-cae7-44b8-a5f7-71b97cabb2bf"
          >
            <div className="intern-banner">
              <div className="overlay">
                <img
                  src="https://www.tunisiajobs.org/themes/custom/jobs_front/assets/img/bannerEffect.svg"
                  alt=""
                />
              </div>
              <img
                src="https://www.tunisiajobs.org/sites/default/files/2021-06/tunisia-jobs-5%20%289%29.png"
                alt=""
              />
              <div className="caption">
                <h1 className="animate__animated animate__fadeInUp animate__slow">
                  <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                    COVID-19 Response
                  </div>
                </h1>
                <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                  <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                    <p>
                      Assisting the<span> recovery</span> of the Tunisian
                      economy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="region region-content">
          <div data-drupal-messages-fallback className="hidden" />
          <div
            id="block-jobs-front-content"
            className="block block-system block-system-main-block"
          >
            <article
              data-history-node-id={47}
              role="article"
              about="/en/covid-19-response"
              typeof="schema:WebPage"
              className="node node--type-page node--view-mode-full"
            >
              <span
                property="schema:name"
                content="COVID-19 Response"
                className="rdf-meta hidden"
              />
              <div className="node__content">
                <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                  <div>
                    <section className="sector-vision covid-sections ">
                      <div className="container">
                        <div className="row">
                          <div className="card-wrapper">
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={0}
                            >
                              <p>
                                <span>Immediate emergency support to SMEs</span>
                                <br />
                                on the front lines of the fight against the
                                pandemic in April 2020
                              </p>
                              <a
                                href="#assistance"
                                className="btn btn-outline-white"
                              >
                                Learn more
                              </a>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              <p>
                                <span>
                                  Assisting the Caisse des Dépôts et
                                  Consignations (CDC)
                                </span>{" "}
                                to conceptualize and begin implementation of the
                                govermment’s investment infusion for SMEs
                              </p>
                              <a href="#cdc" className="btn btn-outline-white">
                                Learn more
                              </a>
                            </div>
                            <div
                              className="card aos-animate"
                              data-aos="fade-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={400}
                            >
                              <p>
                                <span>
                                  Structuring and implementing a $10 million
                                  grants program{" "}
                                </span>{" "}
                                to provide rapid recovery assistance to
                                thousands of SMEs and microentreprises
                              </p>
                              <a
                                href="#grants"
                                className="btn btn-outline-white"
                              >
                                Learn more
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="work" id="assistance">
                      <div className="brick brick--type--work-section brick--id--61 eck-entity container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Immediate assistance to SMEs producing protective
                            equipment and other Covid-19 responses
                          </div>
                        </h2>
                        <div
                          className="work-text aos-animate"
                          data-aos="fade-up"
                        >
                          <p>
                            <span>
                              In April 2020, USAID JOBS provided immediate grant
                              assistance to six SMEs on the front lines of the
                              fight against the pandemic.{" "}
                            </span>
                          </p>
                          <p>
                            The companies were producing protective equipment,
                            processing medical waste, and supporting crisis
                            communications for the Government of Tunisia.
                          </p>
                          <p>
                            This cash equivalent infusion included
                            working-capital support or the purchase of needed
                            equipment. It permitted these companies to continue
                            their operations and serve Tunisians in the struggle
                            against the pandemic.
                          </p>
                        </div>
                        <div className="our-work-carousel">
                          <div className="owl-carousel owl-theme">
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-anchor-placement="top-center"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={100}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={TunisiaJobs} alt="" />
                              <div className="card-text">
                                <h3>Dasri Sterile</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>Medical waste management</span>
                                  {/* <a
                                    href="../stories/a8d8161c0cff4cb79523834aa72b4252.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-anchor-placement="top-center"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={TunisiaJobs51} alt="" />
                              <div className="card-text">
                                <h3>Consomed</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>Textile equipment manufacturing </span>
                                  {/* <a
                                    href="../stories/e75ff702b8ea44cbab80af080e7cac50.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-anchor-placement="top-center"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={300}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={TunisiaJobs52} alt="" />
                              <div className="card-text">
                                <h3>Medicoset</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>Textile equipment manufacturing </span>
                                  {/* <a
                                    href="../stories/098e4bbc0fd740d29a4927deac28e1c0.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-anchor-placement="top-center"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={400}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={TunisiaJobs53} alt="" />
                              <div className="card-text">
                                <h3>Medika-Hitex</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>Textile equipment manufacturing </span>
                                  {/* <a
                                    href="../stories/c99203757b384238a8378c49309f8ed0.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-anchor-placement="top-center"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={500}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={SPI} alt="" />
                              <div className="card-text">
                                <h3>Société Plast Injection Kairouan (SPI)</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>Medical waste management</span>
                                  {/* <a
                                    href="../stories/bc2512e97b744c2eadd0a505a4a07743.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-anchor-placement="top-center"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={600}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={IBS} alt="" />
                              <div className="card-text">
                                <h3>IBS Outsourcing</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>Crisis management outsourcing</span>
                                  {/* <a
                                    href="../stories/71ba84aceed047f28db4253d5879ce59.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="assistance-cdc" id="cdc">
                      <div className="container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                            Assistance to the CDC
                          </div>
                        </h2>
                        <div className="d-flex flex-md-row flex-column">
                          <div
                            className="paragh aos-animate"
                            data-aos="fade-up"
                            data-aos-easing="ease-in-back"
                          >
                            <div className="clearfix text-formatted field field--name-field-left-paragraph field--type-text-long field--label-hidden field__item">
                              <img
                                alt="cdc"
                                data-entity-type="file"
                                data-entity-uuid="0dcc476e-d044-46fc-b0f2-9b65b2379173"
                                src={CDC}
                                style={{
                                  width: "393px",
                                  height: "270px",
                                  marginLeft: 0,
                                  marginBottom: "15px",
                                  marginTop: "09px",
                                }}
                                className="align-center"
                              />
                              <p>
                                USAID JOBS provided USG assistance to the Caisse
                                de Dépôts et des Consignations (CDC) to
                                implement the Government of Tunisia’s 700
                                million TND Emergency Fund to assist Tunisian
                                SMEs.
                              </p>
                            </div>
                          </div>
                          <div
                            className="paragh aos-animate"
                            data-aos="fade-up"
                            data-aos-easing="ease-in-back"
                            data-aos-delay={200}
                          >
                            <div className="clearfix text-formatted field field--name-field-right-paragraph field--type-text-long field--label-hidden field__item">
                              <p>
                                Specifically JOBS supported the CDC to structure
                                and launch four separate sub-funds to support
                                SMEs:
                              </p>
                              <p>&nbsp;</p>
                              <ul>
                                <li>
                                  <b>300 million&nbsp;TND</b> ($107 million)
                                  EMPOWER Fund to support mid to large SMEs to
                                  rebound and sustain growth
                                </li>
                                <br />
                                <li>
                                  <b>100 million TND</b> ($36 million) RELAIS
                                  Fund to provide liquidity for equity
                                  investment funds to reinvest in SMEs
                                </li>
                                <br />
                                <li>
                                  <b>100 million TND</b> ($36 million) IMPACT
                                  Fund for underserved interior regions, and
                                </li>
                                <br />
                                <li>
                                  <b>100 million TND</b> ($36 million) ASPIRE
                                  Fund to support SMEs to implement their
                                  restructuring plans
                                </li>
                                <br />
                                <li>
                                  One financing line totalling{" "}
                                  <b>100 million TND</b> ($36 million)
                                  supporting public health units and hospitals
                                </li>
                              </ul>
                              <p>&nbsp;</p>
                              <p>
                                JOBS is also supporting the CDC in implementing
                                tools to measure the social impact of its
                                investments, particularly SMEs impacted by
                                COVID-19, and those in underserved regions
                                employing women and young people.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="resources" id="grants">
                      <div className="container">
                        <h2
                          className="title-left aos-animate"
                          data-aos="fade-up"
                        >
                          <div className="clearfix text-formatted field field--name-field-title field--type-text field--label-hidden field__item">
                            $10 million grant fund: delivering needed resources
                            to MSMEs
                          </div>
                        </h2>
                        <div
                          className="section-paragh aos-animate"
                          data-aos="fade-up"
                        >
                          <p>
                            The COVID-19 pandemic has severely impacted the
                            Tunisian economy and many businesses are struggling
                            to stay in operation. The U.S. Government is
                            partnering with Tunisia in recovery efforts, and
                            through JOBS has launched a grant fund to accelerate
                            the recovery of thousands of Tunisian businesses
                            nationwide by providing much-needed capital at a
                            critical time.
                          </p>
                          <p>
                            The COVID-19 Enterprise Recovery Grant Fund delivers
                            support for microenterprises impacted by COVID-19 to
                            recover and to re-hire, sustain, or add employees
                            for enterprises delivering essential goods and
                            services to combat the pandemic. The program will
                            sustain or create at least 15,000 jobs and leverage
                            $20 million in private capital and commercial
                            financing.
                          </p>
                          <p>
                            <span>
                              The COVID-19 grant fund has three distinct
                              categories of grants:{" "}
                            </span>
                          </p>
                        </div>
                        <div className="grants-section">
                          <div className="d-flex flex-wrap">
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={0}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={TunisiaJobs54} alt="" />
                              <div className="card-text">
                                <h3>Support for Microenterprises</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>
                                    Mobilizing $31 million to support 13,000
                                    MSMEs
                                  </span>
                                  {/* <a
                                    href="../stories/2f4c23e90ffe4c4a823e484f060b7b2e.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={100}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={grants} alt="" />
                              <div className="card-text">
                                <h3>Partner Grants</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>
                                    Granting JOBS’ ongoing and potential client
                                    firms small business grants{" "}
                                  </span>
                                  {/* <a
                                    href="../stories/f64afef4e436444cbdbef160162a3fd8.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            <div
                              className="item work-card aos-animate"
                              data-aos="zoom-in-up"
                              data-aos-easing="ease-in-back"
                              data-aos-delay={200}
                            >
                              {/* <div className="overlay">
                                <img
                                  src={WorkEffect}
                                  alt=""
                                />
                              </div> */}
                              <img src={grants3} alt="" />
                              <div className="card-text">
                                <h3>Small Grants</h3>
                                <div className="card-info justify-content-between align-items-center">
                                  <span>
                                    Awarding grants to approximately 200 SMEs
                                  </span>
                                  {/* <a
                                    href="../stories/0305ff08a0fc48ad8f2a2f95eef19c09.html"
                                    target="_blank"
                                  >
                                    <img
                                      src={ArrowRight}
                                      alt=""
                                    />
                                  </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="impact aos-animate" data-aos="fade-up">
                      <div className="brick brick--type--impact brick--id--62 eck-entity container">
                        <span className="title-left">
                          <div className="field field--name-field-subtitle field--type-string field--label-hidden field__item">
                            Impact To Date
                          </div>
                        </span>
                        <h2></h2>
                        <ul className="pl-0">
                          <li>
                            <span>
                              <span>
                                254 MSMEs receiving support (80 partners and 174
                                small business grants) throughout Tunisia
                                through the COVID-19 Enterprise Recovery Grant
                                Fund to sustain and create jobs and provide
                                goods and services to combat COVID-19.
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Over 16,445 microenterprises across the country
                                have received grants and loans through the MFI
                                program under the COVID-19 Enterprise Recovery
                                Grant Fund. The total grant funds disbursed are
                                approximately 8 million TND and total lending is
                                93 million TND.
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                CDC Emergency Fund launched in late 2020 with a
                                first closing of 90 million TND.
                              </span>
                            </span>
                          </li>
                          <li>
                            <span>
                              <span>
                                Monitored and engaged over 400 partner companies
                                to provide Covid-related support.
                              </span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);
export default Covid19Response;
